import { Redirect, Route } from "react-router-dom";

// Private Routes
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('token')
            ? <Component 
            {...props} 
            />
            : <Redirect 
            to={{ 
                pathname: '/', 
                state: { from: props.location } 
            }}
             />
    )} />
)

export default PrivateRoute;