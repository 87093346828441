import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Table, Button, Form, InputGroup } from 'react-bootstrap';
import { useState, useEffect } from "react";

import setting from '../../setting.json';
import Topbar from "../../components/Topbar";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const schema = yup
    .object().shape({
        parent: yup.string().required('parent the required'),
        type: yup.string().required('type the required'),
        name: yup.string().required('Name the required'),
        slug: yup.string().required('slug the required'),
        image: yup.string(),
        show_menus: yup.string().required('show_menus the required'),
        position: yup.string().required('position the required'),
        meta_title: yup.string().required('meta_title the required'),
        meta_keybord: yup.string().required('meta_keybord the required'),
        meta_description: yup.string().required('meta_keybord the required'),
        // id: yup.number(),

    })

const Category = () => {
    const [list, setList] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    const [id, setId] = useState('');
    const [add, setAdd] = useState(true);

    const {
        register,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema),
    });


    const addCategory = async (data) => {

        if (add) {
            let ad = new FormData();
            ad.append('pid', data.parent);
            ad.append('type', data.type);
            ad.append('name', data.name);
            ad.append('slug', data.slug);
            ad.append('show_in_nav', data.show_menus);
            ad.append('position', data.position);
            ad.append('meta_title', data.meta_title);
            ad.append('meta_keyword', data.meta_keybord);
            ad.append('meta_desc', data.meta_description);

            var url = setting.api;
            await fetch(url + '/api/addCategory',
                {
                    body: ad,
                    method: "post",
                    mode: "cors", // no-cors, *cors, same-origin
                    headers: {
                        'Accept': 'application/FormData',
                    }
                })
                .then(response => response)
                .then(data => {
                    setAdd(true);
                    getCategories();
                    reset({
                        "parent": '',
                        "type": '',
                        "name": '',
                        "slug": '',
                        "show_menus": '',
                        "position": '',
                        "meta_title": '',
                        "meta_keybord": '',
                        "meta_description": ''

                    })

                });
        } else {
            let ad = new FormData();
            ad.append('id', id);
            ad.append('pid', data.parent);
            ad.append('type', data.type);
            ad.append('name', data.name);
            ad.append('slug', data.slug);
            ad.append('show_in_nav', data.show_menus);
            ad.append('position', data.position);
            ad.append('meta_title', data.meta_title);
            ad.append('meta_keyword', data.meta_keybord);
            ad.append('meta_desc', data.meta_description);

            var url = setting.api;
            await fetch(url + '/api/updateCategory',
                {
                    body: ad,
                    method: "post",
                    headers: {
                        'Accept': 'application/FormData',
                    }
                })
                .then(response => response)
                .then(data => {
                    setAdd(true);
                    reset({
                        "parent": '',
                        "type": '',
                        "name": '',
                        "slug": '',
                        "show_menus": '',
                        "position": '',
                        "meta_title": '',
                        "meta_keybord": '',
                        "meta_description": ''

                    });
                    getCategories();

                });
        }

    }

    const getCategoryById = (id) => {
        setAdd(false);
        setId(id);
        var rec;
        list.filter((a) => {
            if (a.id === id) {
                rec = a;

            }
        })
        reset({
            "parent": rec.pid !== null ? rec.pid : 0,
            "type": rec.type,
            "name": rec.name,
            "slug": rec.slug,
            "show_menus": rec.show_in_nav,
            "position": rec.position,
            "meta_title": rec.meta_title,
            "meta_keybord": rec.meta_keyword,
            "meta_description": rec.meta_desc

        })
    }

    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }
    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }
    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }

    const getCategories = async () => {

        var url = setting.api;
        await fetch(url + '/api/getCategoryList')
            .then(response => response.json())
            .then(json => setList(json)
            );
    }
    const deleteCategory = async (id) => {
        var ans = window.confirm("Do you want to continue? Y/N");
        if (ans == true) {
            var url = setting.api;

            await fetch(url + '/api/deleteCategory?id=' + id)
                .then(response => response)
                .then(json => {
                    getCategories();
                }
                );
        }

    }
    useEffect(() => {
        getCategories();

    }, []);

    return (

        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                {JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Category </h2>
                        <Row>
                            <Col md={5} lg={5}>
                                <Form onSubmit={handleSubmit(addCategory)}>
                                    <Card>
                                        <Card.Header className="head">
                                            {!add ? 'Edit' : 'Add'} Category
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Parent</Form.Label>
                                                    <Form.Select aria-label="Default select example" {...register("parent")}>
                                                        <option value="0">As Parent</option>
                                                        {
                                                            list && list.map((r, index) =>

                                                                <option key={r.id} value={r.id}>{r.name}</option>
                                                            )}
                                                    </Form.Select>
                                                    {errors.parent && <p>{errors.parent.message}</p>}
                                                </Form.Group>

                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Type</Form.Label>
                                                    <Form.Select aria-label="Default select example" {...register("type")}>
                                                        <option value="1">Normal</option>
                                                        <option value="2">State</option>
                                                        <option value="3">City</option>
                                                        <option value="4">Video</option>
                                                        <option value="5">Story</option>
                                                    </Form.Select>
                                                    {errors.type && <p>{errors.type.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Name" {...register("name")} />
                                                    {errors.name && <p>{errors.name.message}</p>}
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Slug</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Slug" {...register("slug")} />
                                                    {errors.slug && <p>{errors.slug.message}</p>}
                                                </Form.Group>

                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Image</Form.Label>
                                                    <Form.Control type="file" placeholder="Enter email" {...register("image")} />

                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Show in Menu</Form.Label>
                                                    <Form.Select aria-label="Default select example" {...register("show_menus")}>
                                                        <option value="0">No</option>
                                                        <option value="1">Yes</option>
                                                    </Form.Select>
                                                    {errors.show_menus && <p>{errors.show_menus.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Position</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Position" {...register("position")} />
                                                    {errors.position && <p>{errors.position.message}</p>}
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Meta Title</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Meta Title" {...register("meta_title")} />
                                                    {errors.meta_title && <p>{errors.meta_title.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Meta Keyword</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Meta Keyword" {...register("meta_keybord")} />
                                                    {errors.meta_keybord && <p>{errors.meta_keybord.message}</p>}
                                                </Form.Group>
                                                <Form.Group as={Col} className="mb-3" >
                                                    <Form.Label>Meta Description</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter Meta Description" {...register("meta_description")} />
                                                    {errors.meta_description && <p>{errors.meta_description.message}</p>}
                                                </Form.Group>
                                            </Row>

                                            <Button type='submit' variant='success'  >Submit</Button>
                                        </Card.Body>
                                    </Card>
                                </Form>

                            </Col>
                            <Col md={7} lg={7}>
                                <Card>
                                    <Card.Header className="head">
                                        Category
                                    </Card.Header>
                                    <Card.Body>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Image</th>
                                                    <th>Name</th>
                                                    <th>Parent</th>
                                                    <th>Menu</th>
                                                    <th>Position</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    list && list.map((r, index) =>
                                                        <tr key={r.id}>
                                                            <td>{index + 1}</td>
                                                            <td><img width="30px" src={`${setting.url}/wp-content/uploads/category/${r.image}`} alt="text" /></td>
                                                            <td>{r.name}</td>
                                                            <td>{r.parent}</td>
                                                            <td>{r.show_in_nav === '1' ? 'yes' : 'no'}</td>
                                                            <td>{r.position}</td>
                                                            <td>
                                                                {r.id !== 1 &&
                                                                    <>
                                                                        <Button variant='link' size='sm' onClick={() => getCategoryById(r.id)} ><EditIcon /></Button>
                                                                        {' '}

                                                                        <Button size="sm" variant="link" id="delete" onClick={() => deleteCategory(r.id)}><DeleteIcon /></Button>
                                                                    </>

                                                                }
                                                            </td>
                                                        </tr >
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                        <Row>
                                            <Col></Col>
                                            {
                                                currentPage !== 1 ?
                                                    <Col >
                                                        <Button className="center" onClick={clickFirst}>First</Button>{' '}
                                                        <Button className="center" onClick={clickPrev}>Prev</Button>
                                                    </Col> : <Col></Col>
                                            }

                                            <Col ><p className="center">Page {currentPage} /{pageCount}</p></Col>
                                            {
                                                currentPage !== pageCount ?
                                                    <Col >

                                                        <Button className="center" onClick={clickNext}>Next</Button>{' '}
                                                        <Button className="center" onClick={clickLast}>Last</Button>
                                                    </Col> : <Col></Col>
                                            }
                                            <Col></Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                }
                {
                    JSON.parse(localStorage.getItem('user')).role !== 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Not Allowed</h2>
                    </Col>
                }
            </Row >


        </Container >

    );
}

export default Category;