import Sidebar from "../components/Sidebar";
import { Container, Row, Col, Card, Form, Button, Toast, ToastContainer } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import "react-datepicker/dist/react-datepicker.css";


import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";
import AddBoxIcon from '@mui/icons-material/AddBox';
import setting from '../setting.json';


const Test = () => {


    const getCategories = async () => {

        await fetch('http://api.mycodeacademy.in/api/getAllProduct')
            .then(response => response.json())
            .then(json => {
                console.log(json.data);
            }
            );
    }




    useEffect(() => {

        getCategories();


    }, []);



    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>

                <Sidebar />



                <Col md={10} lg={10} xs={12} className="rightsect">

                    <h3 className="pagetitle"><AddBoxIcon className="titleicon" /> Add News</h3>
                    <Form >
                        <Row>

                            <Col md={3} xs={12} >

                                <Card >
                                    <Card.Header className="cardhead">Category</Card.Header>
                                    <Card.Body>




                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>


            <Footer />

        </Container >
    );
}
export default Test;