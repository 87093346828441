import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Button, Image, Figure } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import setting from '../../setting.json';
import Topbar from "../../components/Topbar";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from "react";

const schema = yup
    .object().shape({
        portal_name: yup.string().required('Portal Name the required'),
        facebook_id: yup.string(),
        facebook_user: yup.string(),
        instagram_user: yup.string(),
        youtube_user: yup.string(),
        pinterest_user: yup.string(),
        google_code: yup.string(),
        phone: yup.string().required('Phone the required'),


    })

const PortalInfo = () => {
    const [logo, setLogo] = useState('');
    const [icon, setIcon] = useState('');
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema),
    });

    const Update = async (data) => {
        let ad = new FormData();
        ad.append('portal_name', data.portal_name);
        ad.append('facebook_id', data.facebook_id);
        ad.append('facebook_user', data.facebook_user);
        ad.append('instagram_user', data.instagram_user);
        ad.append('youtube_user', data.youtube_user);
        ad.append('pinterest_user', data.pinterest_user);
        ad.append('google_code', data.google_code);
        ad.append('phone', data.phone);

        var url = setting.api;
        await fetch(url + '/api/updatePortalInfo',
            {
                body: ad,
                method: "post",
                mode: "cors",
                headers: {
                    'Accept': 'application/FormData',
                }
            })
            .then(response => response)
            .then(data => {

            });
    }

    const getPortalInfo = async () => {
        var url = setting.api;
        await fetch(url + '/api/getPortalInfo',
            {

                method: "get",
                mode: "cors",
            })
            .then(response => response.json())
            .then(dd => {
                console.log(dd);
                reset({
                    portal_name: dd.name,
                    facebook_id: dd.fbid,
                    facebook_user: dd.fbuser,
                    instagram_user: dd.insta_user,
                    youtube_user: dd.youtube_user,
                    pinterest_user: dd.pintrest_user,
                    google_code: dd.google_verification,
                    phone: dd.phone,
                })
                setLogo(dd.logo);
                setIcon(dd.favicon);

            });
    }


    useEffect(() => {
        getPortalInfo();

    }, []);

    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                {JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Settings</h2>
                        <Card>
                            <Card.Header className="head">
                                Update setting
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(Update)}>
                                    <Row>
                                        <Col md={9}>

                                            <Row>
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Portal Name</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Portal Name" {...register("portal_name")} />
                                                    {errors.portal_name && <p>{errors.portal_name.message}</p>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Facebook ID</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Facebook ID" {...register("facebook_id")} />

                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Facebook User</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Facebook User" {...register("facebook_user")} />

                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Instagram User</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Instagram User" {...register("instagram_user")} />

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Youtube User</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Youtube User" {...register("youtube_user")} />

                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Pinterest User</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Pinterest User" {...register("pinterest_user")} />

                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Google Verification Code</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Google Verification Code" {...register("google_code")} />

                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Phone</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Phone" {...register("phone")} />
                                                    {errors.phone && <p>{errors.phone.message}</p>}
                                                </Col>
                                            </Row>

                                        </Col>
                                        <Col md={3}>
                                            <Row>
                                                <Col >
                                                    <Form.Label className="mt-1 mb-3">Logo</Form.Label>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Figure>
                                                        <Figure.Image
                                                            width={171}
                                                            height={180}
                                                            alt="171x180"
                                                            src={`${setting.url}/wp-content/uploads/${logo}`}
                                                        />

                                                    </Figure>


                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col >
                                                    <Form.Control type="file"  {...register("logo")} />

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col >
                                                    <Form.Label className="mt-1 mb-3">Favicon</Form.Label>

                                                </Col>


                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Figure>
                                                        <Figure.Image
                                                            width={171}
                                                            height={180}
                                                            alt="171x180"
                                                            src={`${setting.url}/wp-content/uploads/${icon}`}
                                                        />

                                                    </Figure>


                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col >
                                                    <Form.Control type="file"  {...register("favicon")} />

                                                </Col>
                                            </Row>




                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <Button type='submit' variant='success'  >
                                                Update
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                }
            </Row >
        </Container >
    );
}
export default PortalInfo;